const config = {
  ENVIRONMENT_NAME: 'testing',
  forwoodId: {
    URL: 'https://id.testing.macquarie.forwoodsafety.com',
    APP_CLIENT_ID: '2lfshh3i7k5m45at9suoru0ld7',
    APIGATEWAY: 'https://nedv8xfqg1.execute-api.eu-west-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://7qit0n5ia7.execute-api.eu-west-1.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'testing',
    VERSION: 'incidents-container-1.7.0',
    HOSTNAME: 'https://incidents.testing.macquarie.forwoodsafety.com',
    INCIDENTS_ACCESS_ROLE: 'IncidentsAccess',
    CREATE_INCIDENT_URL: 'https://iframe.testing.macquarie.forwoodsafety.com/node/add/isi',
    INCIDENT_LIST_URL: 'https://iframe.testing.macquarie.forwoodsafety.com/isi-list',
    IFRAME_ORIGIN_URL: 'https://iframe.testing.macquarie.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;

